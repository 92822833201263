:root {
  --white: #ffffff;
  --red: #cb4545;
}
.qr-large-device-frame {
  max-width: 442px;
  transform: translate(-50%, -50%);
  display: none;
}
.qr-before-body {
  height: 100%;
  background: transparent;
}
.font-10 {
  font-size: 10px;
}
.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-22 {
  font-size: 22px;
}
.font-24 {
  font-size: 24px;
}
.font-28 {
  font-size: 28px;
}
.font-34 {
  font-size: 34px;
}
.line-height-14 {
  line-height: 14px;
}
.line-height-17 {
  line-height: 17px;
}
.line-height-20 {
  line-height: 20px;
}
.line-height-22 {
  line-height: 22px;
}
.line-height-25 {
  line-height: 25px;
}
.line-height-34 {
  line-height: 34px;
}
.line-height-45 {
  line-height: 45px;
}
.pointer-cls {
  cursor: pointer;
}
a {
  text-decoration: none !important;
}
.border-green-btn,
.fill-green-btn,
.fill-green-btn:disabled,
.carousel-cls,
.option-div,
.search-form,
.search-input,
.search-input:focus,
.customize-img,
.selection-div1,
.set-width,
.selection-div2,
.payment-img,
.review-btn {
  width: -webkit-fill-available;
}
/* Desktop Side bar start */
.qr-large-device-left {
  max-width: 294px;
  left: calc(25% - 90px);
  transform: translate(-50%, -50%);
  display: none;
}
.qr-large-device-left .qr-large-device-text {
  line-height: 21px;
  letter-spacing: 0;
  margin: 30px 0 0 0;
}
.qr-large-device-right {
  top: 65%;
  transform: translate(50%, -50%);
  display: none;
  max-width: 348px;
}
.qr-large-device-right .qr-large-device-scan-msg {
  letter-spacing: 0;
  margin: 0 0 10px auto;
  max-width: 120px;
}
.qr-large-device-scan-box {
  max-width: 120px;
  border-radius: 10px;
}
.qr-large-device-scan-arrow {
  max-width: 229px;
  margin-top: -30%;
}
/* Desktop Side bar End */

/* Header Start */
.header-cls {
  padding: 38px 24px 16px;
  height: 122px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}
.arrow-top {
  top: 24px;
}
/* Home Header Start */

/* Home Header Start */
.side-menu {
  flex-direction: column;
  align-items: center;
  padding: 42px 24px 0px;
  height: 128px;
}
.header-inner-cls {
  padding: 40px 14px 0px;
  height: 76px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}
.side-menu-header {
  height: 137px;
}
.img-size {
  width: 48px;
  height: 48px;
}
.offcanvas-header .btn-close {
  padding-right: 25px;
}
.offcanvas-body {
  padding: 0 !important;
}
.side-menu-body {
  top: 192px;
}
/* Home Header End */
/* Button Css Start */
.border-green-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  height: 33px;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
  color: var(--white);
}
.fill-green-btn {
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.15) !important;
  border-radius: 8px !important;
  height: 48px;
}
.fill-green-btn:disabled {
  border-radius: 100px !important;
  height: 45px;
}
/* Button Css End */

/* Splash Screen CSS Start */
.title-bg {
  position: absolute;
  height: 504px;
  left: 0px;
  z-index: 1;
  width: 100%;
}
.title-margin {
  margin-top: 180px;
}
.img-height {
  height: -webkit-fill-available;
}
.img-div-height {
  top: 50%;
}
.animate-on-change {
  display: block !important;
}
/* Splash Screen CSS End */

/* Home Screen CSS Start */
.home-div {
  top: 122px;
  height: -webkit-fill-available;
  overflow-y: scroll;
  overflow-x: hidden;
}
.carosel-img-size {
  height: 130px;
  border-radius: 8px;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: none;
}
.carousel-indicators {
  top: 138px;
}
.carousel-indicators .active {
  width: 26px !important;
}
.scrollSpyContainer {
  float: left;
  top: 0;
  right: 0;
  padding: 0px;
  list-style-type: none;
  width: 104px;
  height: -webkit-fill-available;
  position: sticky;
  overflow-y: scroll;
  overflow-x: hidden;
}
.spyItemClass {
  text-align: center;
  padding: 19px;
}
.spyItemClass:hover {
  cursor: pointer;
}
.active {
  background: var(--white);
}
.cart-btn-gochiso {
  margin-top: -6px;
}
.fix-width {
  display: flow-root;
  overflow-x: hidden;
  height: -webkit-fill-available;
  overflow-y: scroll;
}
.carousel-cls {
  height: 150px;
  border-radius: 8px;
}
.small-icons {
  margin-top: -34px;
}
.best-seller-tag {
  padding: 4px 12px;
  width: 77px;
  height: 22px;
  background: var(--orange-100);
  box-shadow: 3px 4px 4px rgba(51, 51, 51, 0.1);
  border-radius: 24px;
  margin-top: -138px;
}
.sold-out-tag {
  padding: 5px 17px;
  width: 76px;
  height: 22px;
  background: var(--red);
  box-shadow: 3px 4px 4px rgba(51, 51, 51, 0.1);
  border-radius: 24px;
}
.sticky-btn {
  bottom: 30px;
}
.EZDrawer .EZDrawer__container {
  border-radius: 16px 16px 0px 0px;
}
.option-div {
  padding: 24px 16px;
  height: auto;
  border-radius: 16px;
}
.dining-select-div {
  display: -webkit-box;
}
.dining-btn {
  width: fit-content;
  height: 36px;
}
.Modal-module_modalTitle__1TtY9 {
  display: none;
}
.Modal-module_dialog__2UBIn {
  width: auto !important;
}
.Modal-module_modalCloseIcon__20QFz {
  display: none !important;
}
.Modal-module_modal__FqeFw {
  min-width: 0px !important;
}
.css-1igvx5z-timeSelected-time-TopBar,
.css-rt16tf-timeSelected-time {
  padding: 6px 3px;
  border-radius: 8px;
  display: unset !important;
}
.css-1wtkbk-time-TopBar,
.css-nntsr1-time {
  padding: 6px 3px;
  border-radius: 8px;
}
.css-dlroum-meridiem,
.react-timekeeper-button-reset:hover {
  border-radius: 8px !important;
}
.css-1outj7w-meridiem {
  display: none !important;
}
.css-1157z6n-style {
  background: #f4f4f400 !important;
}
.css-cowp6p-styles svg {
  border-radius: 122px;
}

.search-input {
  padding: 8px 10px;
  border-radius: 100px;
  padding-left: 3rem;
}

.search-input:focus {
  padding: 8px 10px;
  border-radius: 100px;
  padding-left: 3rem;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
  outline: 0 !important;
}
.input-search-icon {
  position: absolute;
  font-size: 24px;
}

.form-control::placeholder {
  opacity: 1;
  font-size: 12px;
}

.close-search {
  cursor: pointer;
}

/* Home Screen CSS End */

/* Customise Dish CSS Start */
.inner-div {
  height: -webkit-fill-available;
  overflow-y: scroll;
  overflow-x: hidden;
}
.customize-img {
  height: 180px;
  border-radius: 10px;
}
.selection-div1 {
  align-items: flex-start;
  background: var(--white);
  border-radius: 16px 16px 0px 0px;
}
.selection1 {
  align-items: center;
}
.dot {
  width: 4px;
  height: 4px;
  background: #c6c6c6;
  border-radius: 10px;
}
.selection-text {
  color: #808080;
}
.selection-div2 {
  align-items: flex-start;
  background: var(--white);
}
.cart-button-div {
  box-shadow: 0px -2px 4px rgba(51, 51, 51, 0.15);
}
.qty-class {
  border-radius: 100px;
  padding: 3px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.cart-btn {
  border-radius: 8px !important;
}
.cart-btn:disabled {
  border-radius: 8px !important;
}
/* Customise Dish CSS End */

/* Cart Page CSS Start */
.option-icon {
  width: 56px;
  height: 56px;
  border-radius: 100px;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.cart-img {
  width: 83px;
  height: 72px;
  border-radius: 8px;
}
.edit-btn {
  align-items: center;
  padding: 4px 15px;
  width: 71px;
  height: 24px;
  border-radius: 24px;
}
.popular-img {
  width: 160px;
  height: 120px;
  border-radius: 8px;
}
.title-cls {
  height: 40px;
}
/* Cart Page CSS End */
/* Payments CSS Start */
.payment-div {
  background: var(--black-10);
}
.border-hr {
  border-top: 1px solid #e9e9e9;
}
.card {
  border-radius: 0 !important;
}
.apple-btn {
  background: #333333;
  border-radius: 8px;
  padding: 12px 21px;
}
.payment-img {
  height: 150.48px;
}
.pay-btn {
  background: #7d00d6;
  border-radius: 8px;
  padding: 12px 21px;
}

/* Payment Success Page design start */
.payment-border {
  border-radius: 8px 8px 0px 0px;
  padding: 26px 16px;
}
.payment-border:after {
  content: "";
  position: absolute;
  width: 40px;
  height: 33px;
  border-radius: 50%;
  background: var(--black-10);
  z-index: 3;
  top: 80%;
  left: -24px;
}

.payment-border:before {
  content: "";
  position: absolute;
  width: 40px;
  height: 33px;
  border-radius: 50%;
  background: var(--black-10);
  z-index: 3;
  top: 80%;
  right: -24px;
}
.payment-border-bottom {
  background: var(--white);
  border-radius: 0px 0px 8px 8px;
  padding: 26px 16px;
}
.email-section {
  height: 100%;
  background: var(--white);
  border-radius: 16px 16px 0px 0px;
  min-height: 100%;
}
.send-btn {
  right: 25px;
  background: #979797 !important;
  border-radius: 5px !important;
  border-color: #979797 !important;
  height: 41px;
}
.review-btn {
  background: var(--black-10) !important;
  border-radius: 8px !important;
  border-color: var(--black-10) !important;
}
.arrow-color {
  color: #767676;
}
.form-control {
  height: 42px;
}
/* Payment Success Page Design End */
/* Thnakyou page design Start */
.back-menu-btn {
  background: var(--white) !important;
}
.back-menu-btn:hover {
  color: var(--white) !important;
}
/* Thnakyou page design Start */
/* Payment CSS End */
/* Order CSS Start */
.styles-module_info__BWdHW {
  background-color: var(--black-40) !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  border-radius: 8px;
  width: 202px;
  height: 33px;
}
.info-icon-cls {
  color: #c6c6c6;
  height: 24px;
  width: 24px;
  cursor: pointer;
}
.info-icon-cls:focus {
  outline: none !important;
}
.check-icon-color {
  height: 24px;
  width: 24px;
}
.close-icon-color {
  color: var(--red);
  height: 24px;
  width: 24px;
}

/* Order Details Page Css Start */
.reoder-btn {
  height: 33px;
  background: var(--white) !important;
  border-radius: 100px !important;
}
.leftbox {
  float: left;
  width: 29px;
  background: #f0f7f2;
  border-radius: 4px;
  height: 26px;
  padding: 2px 6px;
}
.middlebox {
  float: left;
  width: 120%;
}
.rightbox {
  float: right;
  width: 40%;
}
.div-height {
  height: inherit;
}
.dashed-border {
  border: 1px dashed var(--black-40);
}
.order-text {
  text-overflow: ellipsis;
  width: 11rem;
}

.option-img {
  padding: 12px 0px 0px 14px;
}
/* Order Details Page Css End */

/* Empty screen Css start */
.empty {
  padding-top: 156px;
}
/* Empty screen Css End */
/* Order CSS End */

/* Header End */

/* Login CSS Start */
.login-logo {
  width: 56px;
  height: 56px;
}
.country-code{
  background: #FFFFFF;
    border: 1px solid #C6C6C6;
    border-radius: 8px;
    padding: 8px;
}
/* Login CSS End */
@media screen and (min-width: 768px) {
  body {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  .qr-large-device-frame,
  .qr-large-device-left,
  .qr-large-device-right {
    display: block;
  }
  .qr-before-body {
    max-width: 318px;
    height: 556px;
    overflow: hidden;
    position: fixed;
    top: calc(50% + 2px);
    left: 50.6%;
    transform: translate(-50%, -50%);
    z-index: 99;
    overflow-y: auto;
    border-radius: 7px;
  }
  .offcanvas.offcanvas-start {
    top: 164px !important;
    left: 724px !important;
    height: 554px;
    width: 280px !important;
    transition: none;
  }
  .title-bg {
    height: 416px;
  }
  .img-div-height {
    top: 49.3%;
  }
  .hotel-title {
    font-size: 23px !important;
    line-height: 36px !important;
  }
  .sticky-btn {
    bottom: 15px;
  }
  .popular-img {
    width: -webkit-fill-available;
  }
  .item {
    margin-right: 10px;
  }
}
@media screen and (min-width: 280px) {
  .popular-img {
    width: -webkit-fill-available;
  }
  .item {
    margin-right: 10px;
  }
}
